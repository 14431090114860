<template>
  <div class="page">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <transaction-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountCenter.capitalFlow')}}</p>
          </div>
          <div class="account-data flow-main">
            <div class="flow-type">
              <p class="title">{{$t('accountCenter.capitalFlowTitle1')}}</p>
              <button @click="depositFlow" :class="{active:flowType==1}">{{$t('accountCenter.depositFlow')}}</button>
              <button @click="withdrawFlow" :class="{active:flowType==2}">{{$t('accountCenter.withdrawFlow')}}</button>
              <button @click="rewardFlow" :class="{active:flowType==3}">{{$t('accountCenter.activityReward')}}</button>
            </div>
            <div v-show="flowType == 1 || flowType == 2" class="flow-type">
              <p class="title">{{$t('accountCenter.capitalFlowTitle2')}}</p>
              <button @click="completed" :class="{active:status==1}">{{$t('accountCenter.completed')}}</button>
              <button @click="notComplete" :class="{active:status==2}">{{$t('accountCenter.notCompleted')}}</button>
            </div>
            <div class="flow-type">
              <p class="title">{{$t('accountCenter.capitalFlowTitle3')}}</p>
              <div class="select-box">
                <select v-model="selectValue" @change="selectTime" id="time" class="select-time">
                  <option v-for="(item,index) in dateTimeList" :key="index" :value="item.value">{{item.name}}</option>
                </select>
              </div>
            </div>
            <!-- 入金流水 -->
            <div v-if="flowType == 1" >
              <div class="flow-box capital-flow-box scrollbarchange">
                <div class="flow-data">
                  <div class="flow-tlt">
                    <span>{{$t('accountCenter.orderNumber')}}</span>
                    <span>{{$t('accountCenter.money')}} (USD)</span>
                    <span>{{$t('accountCenter.depositTite5')}}</span>
                    <span>{{$t('accountCenter.applyTime')}}</span>
                    <span  v-if="status==1">{{$t('accountCenter.depositTime')}}</span>
                    <span>{{$t('accountCenter.state')}}</span>
                  </div>
                  <ul class="flow-cont">
                    <p v-if="listArray == ''" class="null-data">{{$t('accountCenter.dataStatus14')}}</p>
                    <li v-for="(item,index) in listArray" :key="index">
                      <span>{{item.payOrderId}}</span>
                      <span class="plus">+{{item.depositAmount}}</span>
                      <span>{{item.payPlatformName}}</span>
                      <span class="time">{{item.createTime}}</span>
                      <span class="time" v-if="status==1">{{item.updateTime}}</span>
                      <span v-if="item.status == 6">{{$t('accountCenter.completed')}}</span>
                      <span v-if="item.status != 6">{{$t('accountCenter.notCompleted')}}</span></li>
                  </ul>
                </div>
              </div>
              <Paging :total="pageConfig.total" :current-page='currentPage' @currentPage="handCurrenPage"/>
            </div>
            <!-- 出金流水 -->
            <div v-if="flowType == 2">
              <div class="flow-box capital-flow-box scrollbarchange">
                <div class="flow-data">
                  <div class="flow-tlt">
                    <span>{{$t('accountCenter.orderNumber')}}</span>
                    <span>{{$t('accountCenter.money')}} (USD)</span>
                    <span>{{$t('accountCenter.applyTime')}}</span>
                    <span v-if="status==1">{{$t('accountCenter.paymentTime')}}</span>
                    <span>{{$t('accountCenter.state')}}</span>
                  </div>
                  <ul class="flow-cont">
                    <p v-if="listArray == ''" class="null-data">{{$t('accountCenter.dataStatus14')}}</p>
                    <li v-for="(item,index) in listArray" :key="index">
                      <span>{{item.withdrawOrderId}}</span>
                      <span class="reduce">-{{item.withdrawAmount}}</span>
                      <span>{{item.createTime}}</span>
                      <span class="time" v-if="status==1">{{item.updateTime}}</span>
                      <span v-if="item.status == 4">{{$t('accountCenter.completed')}}</span>
                      <span v-if="item.status == -4 || item.status == 5 || item.status == -1 || item.status == -2">{{$t('accountCenter.closed')}}</span>
                      <span v-if="item.status != 4 && item.status != -4 && item.status != 5 && item.status != -1 && item.status != -2">
                          <a href="javascript:;">{{$t('accountCenter.untreated')}}</a><button @click="withdrawSubmit(item.agentOrderNo)" class="withdraw">{{$t('accountCenter.cancelWithdraw1')}}</button>
                      </span>
                      </li>
                  </ul>
                </div>           
              </div>
              <Paging :total="pageConfig.total" :current-page='currentPage' @currentPage="handCurrenPage"/>
            </div>
            
            <!-- 活动奖励 -->
            <div v-if="flowType == 3" >
              <div class="flow-box capital-flow-box scrollbarchange">
                <div class="flow-data">
                  <div class="flow-tlt">
                    <span>{{$t('accountCenter.orderNumber')}}</span><span>{{$t('accountCenter.rewardTitle1')}} (USD)</span><span>{{$t('accountCenter.rewardTime')}}</span><span>{{$t('accountCenter.rewardType')}}</span>
                  </div>
                  <ul class="flow-cont">
                    <p v-if="listArray == ''" class="null-data">{{$t('accountCenter.dataStatus14')}}</p>
                    <li v-for="(item,index) in listArray" :key="index"><span>{{item.payOrderId}}</span><span class="plus">+{{item.paymentAmount}}</span><span>{{item.succeedTime}}</span><span>{{item.kindName}}</span></li>
                  </ul>
                </div>
              </div>
              <Paging :total="pageConfig.total" :current-page='currentPage' @currentPage="handCurrenPage"/>
            </div>         
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import getDate from "@/util/getDate";
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import TransactionNav from '@/components/TransactionNav.vue';
import Paging from '@/components/Paging.vue';
import account from '@/api/account.js';
export default {
  name: "CapitalFlow",
  components: {
    Header,
    LeftMenu,
    TransactionNav,
    Paging
  },
  data() {
    return {
      loginState:2,
      flowType:1,
      startTime:"",
      endTime:"",
      type:"Deposit",
      status:1,
      currentPage:1,
      pageConfig: {
        total:0,
        pageSize:6,
      },
      listArray:[],
      dateTimeList:[
          {
              name: this.$i18n.t('accountCenter.timeSelect1'),
              value:''
          },
          {
              name: this.$i18n.t('accountCenter.timeSelect2'),
              value:''
          },
          {
              name: this.$i18n.t('accountCenter.timeSelect3'),
              value:''
          },
          {
              name: this.$i18n.t('accountCenter.timeSelect4'),
              value:''
          }
      ],
      selectValue:'',
    }
  },
  created() {
    let type = this.$route.query.type;
    if (type === 'withdraw'){
      this.withdrawFlow();
    }
    // 近7天
    const nearlySevenDays = getDate.getCurrWeekDays();
    this.dateTimeList[0].value = nearlySevenDays.starttime+"/"+nearlySevenDays.endtime;
    // 默认选中的时间
    this.selectValue = this.dateTimeList[0].value;
    // this.startTime = nearlySevenDays.starttime;
    // this.endTime = nearlySevenDays.endtime;
    var date1 = new Date(nearlySevenDays.starttime);
    var date2 = new Date(nearlySevenDays.endtime);
    this.startTime = Date.parse(date1);
    this.endTime  = Date.parse(date2);
    // 本月
    const thisMonth = getDate.getCurrMonthDays();
    this.dateTimeList[1].value = thisMonth.starttime+"/"+thisMonth.endtime;
    // 上个月
    const lastMonth = getDate.getLastMonthDays();
    this.dateTimeList[2].value = lastMonth.starttime+"/"+lastMonth.endtime;
    // 近一年
    const nearlyYear = getDate.getCurrYearDays();
    this.dateTimeList[3].value = nearlyYear.starttime+"/"+nearlyYear.endtime;

    // 默认状态显示的入金流水
    this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage);
  },
   
  methods: {
    // 资金流水
    capitalFlow(startTime,endTime,type,status,pageSize,currentPage){
      let data = {
          startTime: startTime,
          endTime: endTime,
          type: type,
          status: status,
          pageSize:pageSize,
          currentPage:currentPage,
        }
      account.cashflow(data).then((res)=>{
        if(res.data.success == 1){
              console.log(res.data.value.total+'总数')
              this.pageConfig.total = res.data.value.total;
              this.pageConfig = JSON.parse(JSON.stringify(this.pageConfig));
              this.currentPage = res.data.value.currentPage;
              let array = res.data.value.resultList;
              for (let i = 0; i < array.length; i++) {
                let createTime = array[i].createTime;
                let updateTime = array[i].updateTime;
                let succeedTime = array[i].succeedTime;               
                array[i].createTime =  this.timestampToTime(createTime);
                array[i].updateTime =  this.timestampToTime(updateTime);
                array[i].succeedTime =  this.timestampToTime(succeedTime);
              }
              this.listArray = array;
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    },
    // 将时间戳转换为日期格式
    timestampToTime(timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() < 10 ? '0'+ date.getDate(): date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0'+ date.getHours(): date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0'+ date.getMinutes(): date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0'+ date.getSeconds(): date.getSeconds());
        return Y+M+D+h+m+s;
    },
    // 入金流水
    depositFlow(){
      this.flowType = 1
      this.type = "Deposit";
      this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage)
    },
    // 出金流水
    withdrawFlow(){
      this.flowType = 2
      this.type = "Withdraw";
      this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage)
    },
    // 活动奖励
    rewardFlow(){
      this.flowType = 3
      this.type = "Reward";
      this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage)
    },
    // 已完成
    completed(){
      this.status = 1
      if(this.flowType == 1){
        this.type = "Deposit";
      }else if(this.flowType == 2){
        this.type = "Withdraw";
      }
      this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage)
    },
    // 未完成
    notComplete(){
      this.status = 2
      if(this.flowType == 1){
        this.type = "Deposit";
      }else if(this.flowType == 2){
        this.type = "Withdraw";
      }
      this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage)
    },

    // 时间下拉选中方法
    selectTime(){
      var timeAttr = [];
      timeAttr=this.selectValue.split("/");
      if(this.flowType == 1){
        this.type = "Deposit";
      }else if(this.flowType == 2){
        this.type = "Withdraw";
      }
      // this.startTime = timeAttr[0];
      // this.endTime = timeAttr[1];
      var date1 = new Date(timeAttr[0]);
      var date2 = new Date(timeAttr[1]);
      this.startTime = Date.parse(date1);
      this.endTime  = Date.parse(date2);
      this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage)
      console.log(timeAttr)
    },

    // 取消出金申请
    withdrawSubmit(agentOrderId){
      let data = {
          agentOrderNo:agentOrderId,
        }
      account.cancelWithdraw(data).then((res)=>{
        if(res.data.success == 1){
              this.type = "Withdraw";
              this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage)
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    },

    // 分页调用方法
    handCurrenPage(val){
      this.currentPage = val
      this.capitalFlow(this.startTime,this.endTime,this.type,this.status,this.pageConfig.pageSize,this.currentPage)
    　　console.log(val)
    }
    
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
